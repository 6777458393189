import {
    // CarOutlined,
    // CrownOutlined,
    DashboardOutlined,
    EditOutlined,
    LayoutOutlined,
    ProfileOutlined,
    ShoppingCartOutlined,
    UsergroupAddOutlined,
    UserOutlined,
} from '@ant-design/icons'
import { ROLES } from '@constants/constants'
import { TRoles } from '@constants/interface'
// import React from 'react'

export default {
    route: {
        path: '/',
        routes: [
            {
                path: '/dashboard',
                name: 'Dashboard',
                icon: <DashboardOutlined />,
                exact: true,
                roles: [ROLES.ADMIN, ROLES.LOGISTIC, ROLES.SALE, ROLES.ACCOUNT,ROLES.LOGISTIC_VIEW,ROLES.SALE_VIEW,ROLES.ACCOUNT_VIEW],
            },
            {
                name: 'Customer',
                locale: '/customer-management',
                path: '/customer-management/list-customer',
                icon: <UsergroupAddOutlined />,
                roles: [ROLES.ADMIN, ROLES.LOGISTIC, ROLES.SALE, ROLES.ACCOUNT,ROLES.LOGISTIC_VIEW,ROLES.SALE_VIEW,ROLES.ACCOUNT_VIEW],
            },
            {
                name: 'Order Sheet',
                locale: '/os-management',
                path: '/os-management',
                icon: <ProfileOutlined />,
                roles: [ROLES.ADMIN, ROLES.LOGISTIC, ROLES.SALE,ROLES.LOGISTIC_VIEW,ROLES.SALE_VIEW] as TRoles[],
            },
            {
                name: 'E-signature',
                locale: '/e-signature-management',
                path: '/e-signature-management',
                icon: <EditOutlined />,
                roles: [ROLES.ADMIN, ROLES.LOGISTIC, ROLES.SALE, ROLES.ACCOUNT,ROLES.LOGISTIC_VIEW,ROLES.SALE_VIEW,ROLES.ACCOUNT_VIEW],
            },
            {
                name: 'Document',
                locale: '/document-management',
                path: '/document-management',
                icon: <ShoppingCartOutlined />,
            },
            {
                name: 'Template',
                locale: '/template-management',
                path: '/template-management',
                icon: <LayoutOutlined />,
                roles: [ROLES.ADMIN, ROLES.LOGISTIC, ROLES.SALE,ROLES.LOGISTIC_VIEW,ROLES.SALE_VIEW] as TRoles[],
            },
            {
                name: 'User',
                locale: '/user-management',
                path: '/user-management',
                icon: <UserOutlined />,
                roles: [ROLES.ADMIN] as TRoles[],
            },
            {
                name: 'Product Description',
                locale: '/product-description',
                path: '/product-description',
                icon: <ProfileOutlined />,
                roles: [ROLES.ADMIN, ROLES.LOGISTIC,ROLES.LOGISTIC_VIEW] as TRoles[],
            },
            {
                name: 'Payment Information',
                locale: '/payment-information',
                path: '/payment-information',
                icon: <ProfileOutlined />,
                roles: [ROLES.ADMIN, ROLES.ACCOUNT,ROLES.ACCOUNT_VIEW] as TRoles[],
            },
        ],
    },
    location: {
        pathname: '/',
    },
}
