import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { ConfigProvider } from 'antd'
import en_US from 'antd/lib/locale/en_US'

import MainContextProvider from './context/index'

import './index.css'
import './antd.less'
import './App.css'

ReactDOM.render(
    <ConfigProvider locale={en_US}>
        <MainContextProvider>
            <App />
        </MainContextProvider>
    </ConfigProvider>,
    document.getElementById('root') as HTMLElement,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
