// import { api } from '@configs/axios';
// import { getCookie } from '@configs/cookie';
import { getUnreadNotifications } from '@api/notification'
import { LoadingLazyComponent } from '@components/loading-page'
import { socket } from '@configs/socket.io'
import { Spin, BreadcrumbProps } from 'antd'
import React, {
    memo,
    Suspense,
    useCallback,
    useEffect,
    useReducer,
} from 'react'
import { api } from 'src/configs/axios'
import { getCookie } from 'src/configs/cookie'
import { ACCESS_TOKEN } from '../constants'

export interface IMainContext {
    state?: IMainState | null
    setState: React.Dispatch<IMainState>
}

export interface IMainState {
    user?: any
    isLoadingInit?: boolean
    breadcrumb?: BreadcrumbProps
    notificationCount?: number
}

export const MainContext = React.createContext<IMainContext>({
    state: null,
    setState: () => {},
})

export const useAppContext = () => React.useContext(MainContext)
export const useUser = () => {
    const { state, setState } = useAppContext()

    const setUser = useCallback(
        (user: any) => {
            setState({
                user,
            })
        },
        [setState],
    )

    return {
        user: state?.user,
        setUser,
    }
}

const MainContextProvider = ({ children }: any) => {
    const [state, setState] = useReducer(
        (prev: IMainState, state: IMainState) => ({
            ...prev,
            ...state,
        }),
        {
            user: null,
            isLoadingInit: true,
            breadcrumb: {
                routes: [
                    {
                        path: '/',
                        breadcrumbName: 'Home',
                    },
                ],
            },
            notificationCount: 0,
        },
    )

    useEffect(() => {
        if (localStorage.getItem(ACCESS_TOKEN))
            api.get('/auth/profile')
                .then((data: any) => {
                    setState({
                        user: data?.data,
                    })
                    getUnreadNotificationsCount()
                    socket.connect()
                    socket.on('connected', () => {
                        socket.on('new-notification', () => {
                            getUnreadNotificationsCount()
                        })
                    })
                })
                .catch((err: any) => {
                    console.log(err)
                })
                .finally(() => {
                    setState({
                        isLoadingInit: false,
                    })
                })
        else {
            setState({
                isLoadingInit: false,
            })
        }
        return () => {
            socket.removeListener('new-notification')
            socket.removeListener('connected')
            socket.disconnect()
        }
    }, [])

    useEffect(() => {
        if (!state?.user) return
        const interval = setInterval(getUnreadNotificationsCount, 30000)
        return () => clearInterval(interval)
    }, [state?.user])

    const getUnreadNotificationsCount = () => {
        getUnreadNotifications().then((res) => {
            if (res?.data?.unread && res?.data?.unread > 0)
                setState({
                    notificationCount: res?.data?.unread || 0,
                })
        })
    }

    return (
        <MainContext.Provider
            value={{
                state,
                setState,
            }}
        >
            {state.isLoadingInit ? (
                <div
                    style={{
                        height: '100vh',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Spin />
                </div>
            ) : (
                children
            )}
        </MainContext.Provider>
    )
}

export default memo(MainContextProvider)
