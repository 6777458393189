import React, { memo, useEffect } from 'react';
import styles from "./styles.module.scss";
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

interface LoadingLazyComponentProps {
}

const LoadingLazyComponent: React.FC<LoadingLazyComponentProps> = memo((props) => {

    useEffect(() => {
        NProgress.start();
        return () => { NProgress.done() }
    }, []);

    return (
        <div className={styles.loading}>
        </div>
    );
})

export {
    LoadingLazyComponent
};