import React, { Suspense, useCallback, useEffect } from 'react'
import {
    BellOutlined,
    EditOutlined,
    FundViewOutlined,
    LogoutOutlined,
    QuestionCircleOutlined,
    UserOutlined,
} from '@ant-design/icons'
import ProLayout from '@ant-design/pro-layout'
import { LoadingLazyComponent } from '@components/loading-page'
import { Badge, Col, Dropdown, Image, Menu, MenuProps, Row } from 'antd'
import defaultProps from './defaultProps'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useAppContext } from '@context/index'
import styles from './styles.module.scss'
import Logo from '@assets/images/logo.png'
import { api } from '@configs/axios'
import { forgetCookie } from '@configs/cookie'
import {
    ACCESS_TOKEN,
    PAGES,
    primaryColor,
    REFRESH_TOKEN,
} from '@constants/constants'
import EditProfile from '@pages/profile/edit-profile'
import Avatar from '@assets/images/avatar.png'
import { getAvatar } from '@api/user'
import NotificationList from './NotificationList'
import NotificationDropDown from './NotificationDropDown'
import {
    getUnreadNotifications,
    postReadAllNotifications,
} from '@api/notification'

const AdminLayout = (props: any) => {
    const location = useLocation()
    const navigate = useNavigate()
    const { state, setState } = useAppContext()
    const user = state?.user
    const roles = user?.roles || []

    useEffect(() => {
        if (location.pathname) {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            })
        }
    }, [location.pathname])

    useEffect(() => {
        if (!state?.isLoadingInit && !state?.user)
            navigate('/sign-in', { replace: true })
    }, [state, navigate])

    const logout = useCallback(() => {
        // setLoading(true)
        api.delete('/auth/logout')
            .then(() => {
                localStorage.removeItem(ACCESS_TOKEN)
                localStorage.removeItem(REFRESH_TOKEN)
                window.location.reload()
            })
            .catch(() => console.log('err'))
        // localStorage.removeItem(REFRESH_TOKEN)
        // localStorage.removeItem(ACCESS_TOKEN)
        // window.location.reload()
    }, [])

    const viewProfile = () => {
        navigate(PAGES.VIEW_PROFILE)
    }

    const handleMenuClick: MenuProps['onClick'] = (e) => {
        switch (e.key) {
            case 'viewProfile':
                viewProfile()
                break
            case 'logout':
                logout()
                break
            default:
                break
        }
    }

    const menu = (
        <Menu
            onClick={handleMenuClick}
            items={[
                {
                    label: 'View Profile',
                    key: 'viewProfile',
                    icon: <FundViewOutlined />,
                },
                {
                    label: 'Logout',
                    key: 'logout',
                    icon: <LogoutOutlined />,
                },
            ]}
        />
    )

    const propsLayout = {
        ...defaultProps,
        route: {
            ...defaultProps.route,
            routes: defaultProps.route.routes
                .map((router) => {
                    return router.roles &&
                        router.roles.length > 0 &&
                        !router.roles.some((role) =>
                            (roles || []).includes(role),
                        ) &&
                        !user?.isRootAdmin
                        ? null
                        : router
                })
                .filter((router) => router),
        },
    }

    const readAllNotifications = () => {
        postReadAllNotifications().then((res) => {
            setState({
                notificationCount: res?.data?.unread || 0,
            })
        })
    }

    return (
        <ProLayout
            className={styles['main-layout']}
            title="N.P.FOODS"
            logo={<img src={Logo} width={32} height={32} />}
            {...propsLayout}
            location={{
                pathname: location.pathname,
            }}
            selectedKeys={[
                `/${location.pathname?.split('/')[1]}`,
                location.pathname,
                ...(location.pathname === '/' ? ['/dashboard'] : []),
            ]}
            waterMarkProps={{
                content: 'Admin Layout',
            }}
            navTheme=" "
            theme="light"
            primaryColor={primaryColor}
            menuItemRender={(item: any, dom: any) => (
                <Link to={item.path || '/'}>{dom}</Link>
            )}
            rightContentRender={() => (
                <Row gutter={[26, 0]}>
                    <Col>
                        <NotificationDropDown onClick={readAllNotifications}>
                            <Badge
                                count={state?.notificationCount || 0}
                                size="small"
                            >
                                <BellOutlined
                                    size={32}
                                    style={{ color: '#fff', height: '30px' }}
                                />
                            </Badge>
                        </NotificationDropDown>
                    </Col>
                    <Col>
                        <Dropdown
                            className={styles['drop-profile']}
                            overlay={menu}
                        >
                            <Row align="middle" gutter={[8, 0]}>
                                <Col className={styles['view-image']}>
                                    <Image
                                        width={30}
                                        height={30}
                                        {...(state?.user?.avatar
                                            ? {
                                                  src: getAvatar(
                                                      state?.user?.avatar,
                                                  ),
                                              }
                                            : {})}
                                        fallback={Avatar}
                                        preview={false}
                                    />
                                </Col>
                                <Col>
                                    <p className={styles['user-name']}>
                                        {state?.user?.username}
                                    </p>
                                </Col>
                            </Row>
                        </Dropdown>
                    </Col>
                </Row>
            )}
            fixSiderbar
            fixedHeader
        >
            <Suspense fallback={<LoadingLazyComponent />}>
                {props.children}
            </Suspense>
        </ProLayout>
    )
}

export default AdminLayout
