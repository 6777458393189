import { ACCESS_TOKEN } from '@constants/constants'
import { io } from 'socket.io-client'

export const socket = io(process.env.REACT_APP_WS_URL || '', {
    path: process.env.REACT_APP_WS_PATH || '/api/v1/socket.io',
    transportOptions: {
        polling: {
            extraHeaders: {
                Authorization: localStorage.getItem(ACCESS_TOKEN),
            },
        },
    },
    autoConnect: false,
})
