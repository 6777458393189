import { Button, Col, List, Row, Typography } from 'antd'
import { memo } from 'react'

const Header = ({ onReload }: any) => {
    return (
        <Row className="w-100" justify="center">
            <Button type="link" onClick={() => onReload && onReload()}>
                Reload
            </Button>
        </Row>
    )
}

export default memo(Header)
