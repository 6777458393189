import { api } from 'src/configs/axios'

export const getAllUserPagination = (filter?: any) =>
    api.get(`/users`, { params: filter })
export const getAllUserPaginationPage = (filter?: any) =>
    api.get(`/users/page-info`, { params: filter })
export const getUserByIdOrUsername = (_id: string) => api.get(`/users/${_id}`)
export const postCreateUser = (body: any) => api.post(`/users/create`, body)
export const putUpdateUser = (_id: string, body: any) =>
    api.put(`/users/${_id}`, body)
export const deleteRemoveUser = (_id: string) => api.delete(`/users/${_id}`)
export const getSearchUsername = (s: string, params: any = {}) =>
    api.get(`/users/search`, { params: { s, ...params } })
export const updatePassword = (
    _id: string,
    oldPassword: string,
    password: string,
    repeat_password: string,
) =>
    api.put(`/users/change-password/${_id}`, {
        oldPassword,
        password,
        repeat_password,
    })
export const getAvatar = (filename: string) => {
    return `${process.env.REACT_APP_BACKEND_URL}/users/avatar/${filename}`
}
export const updateAvatar = (body: any) => {
    const formData = new FormData()
    formData.append('file', body)
    return api.post(`/users/avatar`, formData)
}
