import { ROLES } from '@constants/constants'
import { TRoles } from '@constants/interface'

export interface IRouter {
    exact: boolean
    path: string
    component: string
    noHeader?: boolean
    option?: any
    title?: string
    sidebar?: boolean
    roles?: TRoles[]
}

export const routersNotAuth: IRouter[] = [
    {
        exact: true,
        path: '/sign-in',
        component: 'sign-in',
        noHeader: true,
    },
    {
        exact: true,
        path: '/sign-up',
        component: 'sign-up',
        noHeader: true,
    },
    {
        exact: true,
        path: '/forgot-password',
        component: 'forgot-password',
        noHeader: true,
    },
    {
        exact: true,
        path: '/reset-password',
        component: 'reset-password',
        noHeader: true,
    },
]

export const routersAuth: IRouter[] = [
    {
        exact: true,
        path: '/profile',
        component: 'profile',
        noHeader: true,
    },
    {
        exact: true,
        path: '/profile/edit-profile',
        component: 'profile/edit-profile',
        noHeader: true,
    },
    {
        exact: true,
        path: '/profile/view-profile',
        component: 'profile/view-profile',
        noHeader: true,
    },
    {
        exact: true,
        path: '/',
        component: 'dashboard',
        noHeader: true,
    },
    {
        exact: true,
        path: '/dashboard',
        component: 'dashboard',
        noHeader: true,
    },
    {
        exact: true,
        path: '/export-pdf',
        component: 'export-pdf',
        noHeader: true,
    },
    {
        exact: true,
        path: '/export-pdf/pdf-demo',
        component: 'export-pdf/pdfdemo',
        noHeader: true,
    },
    {
        exact: true,
        path: '/user-management',
        component: 'user-management',
        noHeader: true,
        roles: [ROLES.ADMIN] as TRoles[],
    },
    {
        exact: true,
        path: '/user-management/add-user',
        component: 'user-management/add-user',
        noHeader: true,
        roles: [ROLES.ADMIN] as TRoles[],
    },
    {
        exact: true,
        path: '/user-management/edit-user/:username',
        component: 'user-management/add-user',
        noHeader: true,
        roles: [ROLES.ADMIN] as TRoles[],
    },
    {
        exact: true,
        path: '/user-management/view-user/:username',
        component: 'user-management/add-user',
        noHeader: true,
        roles: [ROLES.ADMIN] as TRoles[],
    },
    {
        exact: true,
        path: '/customer-management',
        component: 'customer',
        noHeader: true,
    },
    {
        exact: true,
        path: '/customer-management/list-customer',
        component: 'customer/list-customer',
        noHeader: true,
        roles: [
            ROLES.ADMIN,
            ROLES.LOGISTIC,
            ROLES.SALE,
            ROLES.ACCOUNT,
            ROLES.LOGISTIC_VIEW,
            ROLES.SALE_VIEW,
            ROLES.ACCOUNT_VIEW,
        ] as TRoles[],
    },
    {
        exact: true,
        path: '/customer-management/add-customer',
        component: 'customer/add-customer',
        noHeader: true,
    },
    {
        exact: true,
        path: '/customer/edit-customer/:_id',
        component: 'customer/edit-customer',
        noHeader: true,
        roles: [ROLES.ADMIN, ROLES.LOGISTIC, ROLES.SALE] as TRoles[],
    },
    {
        exact: true,
        path: '/supplier-management/add-supplier',
        component: 'supplier/add-supplier',
        noHeader: true,
    },
    {
        exact: true,
        path: '/supplier-management/view-supplier/:_id',
        component: 'supplier/view-supplier',
        noHeader: true,
    },
    {
        exact: true,
        path: '/supplier-management/edit-supplier/:_id',
        component: 'supplier/add-supplier',
        noHeader: true,
    },
    {
        exact: true,
        path: '/supplier-management',
        component: 'supplier/list-supplier',
        noHeader: true,
    },
    {
        exact: true,
        path: '/os-management',
        component: 'os-management',
        noHeader: true,
        roles: [ROLES.ADMIN, ROLES.LOGISTIC, ROLES.SALE,ROLES.LOGISTIC_VIEW,ROLES.SALE_VIEW] as TRoles[],
    },
    {
        exact: true,
        path: '/os-management/view-os/:_id',
        component: 'os-management/view-os',
        noHeader: true,
        roles: [ROLES.ADMIN, ROLES.LOGISTIC, ROLES.SALE,ROLES.LOGISTIC_VIEW,ROLES.SALE_VIEW] as TRoles[],
    },
    {
        exact: true,
        path: '/template-management',
        component: 'template-management/list-template',
        noHeader: true,
        roles: [ROLES.ADMIN, ROLES.LOGISTIC, ROLES.SALE, ROLES.ACCOUNT,ROLES.LOGISTIC_VIEW,ROLES.SALE_VIEW,ROLES.ACCOUNT_VIEW] as TRoles[],
    },
    {
        exact: true,
        path: '/e-signature-management',
        component: 'e-signature-management',
        noHeader: true,
    },
    {
        exact: true,
        path: '/e-signature-management/add-e-signature',
        component: 'e-signature-management/form-e-signature',
        noHeader: true,
    },
    {
        exact: true,
        path: '/e-signature-management/edit-e-signature/:id',
        component: 'e-signature-management/form-e-signature',
        noHeader: true,
    },
    {
        exact: true,
        path: '/document-management',
        component: 'document-management',
        noHeader: true,
    },
    {
        exact: true,
        path: '/template-management/add-template',
        component: 'template-management/add-template',
        noHeader: true,
        roles: [ROLES.ADMIN, ROLES.LOGISTIC] as TRoles[],
    },
    {
        exact: true,
        path: '/template-management/edit-template/:_id',
        component: 'template-management/add-template',
        noHeader: true,
        roles: [ROLES.ADMIN, ROLES.LOGISTIC] as TRoles[],
    },
    {
        exact: true,
        path: '/order-management/edit-order/:orderId',
        component: 'order-management/form-order',
        noHeader: true,
    },
    {
        exact: true,
        path: '/product-description',
        component: 'product-description/list-product',
        noHeader: true,
        roles: [ROLES.ADMIN, ROLES.LOGISTIC,ROLES.LOGISTIC_VIEW] as TRoles[],
    },
    {
        exact: true,
        path: '/product-description/add-product',
        component: 'product-description/add-product',
        noHeader: true,
        roles: [ROLES.ADMIN, ROLES.LOGISTIC] as TRoles[],
    },
    {
        exact: true,
        path: '/product-description/edit-product/:_id',
        component: 'product-description/add-product',
        noHeader: true,
        roles: [ROLES.ADMIN, ROLES.LOGISTIC] as TRoles[],
    },
    {
        exact: true,
        path: '/payment-information',
        component: 'payment-information',
        noHeader: true,
        roles: [ROLES.ADMIN, ROLES.ACCOUNT,ROLES.ACCOUNT_VIEW] as TRoles[],
    },
]

export const documentRoutes: IRouter[] = [
    {
        exact: true,
        path: '/view-document',
        component: 'document-management/view-document',
        noHeader: true,
    },
    {
        exact: true,
        path: '/edit-document',
        component: 'document-management/form-document',
        noHeader: true,
    },
    {
        exact: true,
        path: '/edit-document/review-os/:_id',
        component: 'document-management/review-os',
        noHeader: true,
    },

    {
        exact: true,
        path: '/edit-document/review-sale-contract',
        component: 'document-management/review-sale-contract',
        noHeader: true,
    },
    {
        exact: true,
        path: '/preview-LC/:id',
        component: 'document-management/preview-document/preview-LC',
        noHeader: true,
        roles: [ROLES.ADMIN, ROLES.LOGISTIC, ROLES.LOGISTIC_VIEW] as TRoles[],
    },
    {
        exact: true,
        path: '/preview-template-data/:id',
        component: 'document-management/preview-document/preview-template-data',
        noHeader: true,
        roles: [ROLES.ADMIN, ROLES.LOGISTIC, ROLES.LOGISTIC_VIEW] as TRoles[],
    },
    {
        exact: true,
        path: '/preview-shipping-mark',
        component: 'document-management/preview-document/preview-SM',
        noHeader: true,
        roles: [ROLES.ADMIN, ROLES.LOGISTIC,ROLES.LOGISTIC_VIEW] as TRoles[],
    },
]

export const paymentRoutes: IRouter[] = [
    {
        exact: true,
        path: '/edit-payment',
        component: 'payment-information/edit-payment',
        noHeader: true,
        roles: [ROLES.ADMIN, ROLES.ACCOUNT] as TRoles[],
    },
]
