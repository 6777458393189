import { Dropdown, List, Typography } from 'antd'
import { FunctionComponent, memo, ReactElement, useState } from 'react'
import NotificationList from '../NotificationList'
import styles from './styles.module.scss'

const NotificationDropDown: FunctionComponent<{
    children: ReactElement
    onClick: () => void
}> = ({ children, onClick }) => {
    const [visible, setVisible] = useState<boolean>(false)

    return (
        <Dropdown
            className={`${styles['drop-profile']} ${styles['drop-notification']}`}
            overlay={() => (
                <NotificationList
                    visible={visible}
                    onClose={() => setVisible(false)}
                />
            )}
            trigger={['click']}
            overlayClassName={`${styles['drop-notification-overlay']}`}
            onVisibleChange={(value) => {
                if (typeof value === 'boolean') {
                    if (value) {
                        onClick && onClick()
                    }
                    setVisible(value)
                }
            }}
            visible={visible}
        >
            {children}
        </Dropdown>
    )
}

export default memo(NotificationDropDown)
