export const ACCESS_TOKEN = 'access_token'
export const REFRESH_TOKEN = 'refresh_token'
export const FORMAT_DATE = 'DD/MM/YYYY'
export const FORMAT_DATE_PI = 'MM.YYYY'
export const FORMAT_DATE_ETD = 'MM/YYYY'
export const FORMAT_DATE2 = 'DD.MM.YYYY'
export const FORMAT_MONTH = 'MM/YYYY'

export const defaultPagination = {
    current: 1,
    pageSize: 20,
    total: 0,
    position: ['bottomRight'],
    responsive: true,
    size: 'small',
    showSizeChanger: true,
}

export const defaultPaginationMini = {
    defaultCurrent: 1,
    defaultPageSize: 10,
    position: ['bottomRight'],
    responsive: true,
    size: 'small',
    showSizeChanger: true,
}

export const PAGES = {
    USER_MANAGEMENT: '/user-management',
    USER_MANAGEMENT_LIST_USER: '/user-management',
    USER_MANAGEMENT_ADD_USER: '/user-management/add-user',
    USER_MANAGEMENT_VIEW_USER: (username: string) =>
        `/user-management/view-user/${username}`,
    USER_MANAGEMENT_EDIT_USER: (username: string) =>
        `/user-management/edit-user/${username}`,
    SUPPLIER_MANAGEMENT: 'supplier-management',
    SUPPLIER_MANAGEMENT_DELETE_SUPPLIER: (_id: string) =>
        `/supplier-management/delete-supplier/${_id}`,
    SUPPLIER_MANAGEMENT_VIEW_SUPPLIER: (_id: string) =>
        `/supplier-management/view-supplier/${_id}`,
    SUPPLIER_MANAGEMENT_EDIT_SUPPLIER: (_id: string) =>
        `/supplier-management/edit-supplier/${_id}`,
    SUPPLIER_MANAGEMENT_LIST_SUPPLIER: () => `/supplier-management`,
    SUPPLIER_MANAGEMENT_ADD_SUPPLIER: () => `/supplier-management/add-supplier`,
    E_SIGNATURE_MANAGEMENT_VIEW_E_SIGNATURE: (_id: string) =>
        `/e-signature-management/view-e-signature/${_id}`,
    E_SIGNATURE_MANAGEMENT_EDIT_E_SIGNATURE: (_id: string) =>
        `/e-signature-management/edit-e-signature/${_id}`,
    E_SIGNATURE_MANAGEMENT_LIST_E_SIGNATURE: `/e-signature-management`,
    E_SIGNATURE_MANAGEMENT_ADD_E_SIGNATURE: `/e-signature-management/add-e-signature`,
    DOCUMENT_MANAGEMENT_VIEW_ORDER: (_id: string) =>
        `/document-management/${_id}/view-document`,
    DOCUMENT_MANAGEMENT_EDIT_ORDER: (_id: string) =>
        `/document-management/${_id}/edit-document`,
    DOCUMENT_MANAGEMENT_LIST_ORDER: `/document-management`,
    DOCUMENT_MANAGEMENT_ADD_ORDER: `/document-management/add-document`,
    DOCUMENT_MANAGEMENT_ADD_ORDER_REVIEW_OS: `/document-management/edit-document/review-os`,
    TEMPLATE_MANAGEMENT_ADD_TEMPLATE: `/template-management/add-template`,
    TEMPLATE_MANAGEMENT_EDIT_TEMPLATE: (_id: string) =>
        `/template-management/edit-template/${_id}`,
    TEMPLATE_MANAGEMENT_LIST_TEMPLATE: `/template-management`,

    OS_MANAGEMENT_LIST_OS: `/os-management`,
    OS_MANAGEMENT_VIEW_OS: (_id: string) => `/os-management/view-os/${_id}`,
    PROFILE: '/profile',
    DASHBOARD: '/dashboard',
    HOME: '/',
    SIGN_IN: '/sign-in',
    FORGOT_PASSWORD: '/forgot-password',
    EDIT_PROFILE: `/profile/edit-profile`,
    VIEW_PROFILE: `/profile/view-profile`,

    // CUSTOMER

    CUSTOMER_MANAGEMENT_EDIT_CUSTOMER: (_id: string) =>
        `/customer/edit-customer/${_id}`,
    CUSTOMER_MANAGEMENT_LIST_CUSTOMER: `/customer-management/list-customer`,
    //

    // PRODUCT
    PRODUCT_DESCRIPTION_ADD_PRODUCT: '/product-description/add-product',
    PRODUCT_DESCRIPTION_LIST_PRODUCT: '/product-description',
    PRODUCT_DESCRIPTION_EDIT_PRODUCT: (_id: string) =>
        `/product-description/edit-product/${_id}`,

    // DOCUMENT
    DOCUMENT_MANAGEMENT_LIST_DOCUMENT: '/document-management',
    DOCUMENT_MANAGEMENT_CREATE_DOCUMENT: '/document-management/create-document',
    // SHIPPING DOCS
    SHIPPING_DOC_CHOOSE_E_SIGNATURE: (_id: string) =>
        `/document/document-template/${_id}`,
    REVIEW_OS: (_id: string, documentId: string) =>
        `/document-management/${documentId}/edit-document/review-os/${_id}`,
    REVIEW_LC: (_id: string, documentId: string) =>
        `/document-management/${documentId}/preview-LC/${_id}`,
    REVIEW_PI: (_id: string) => `/document-management/preview-PI/${_id}`,
    REVIEW_SL: (_id: string) =>
        `/document-management/edit-document/review-sale-contract/${_id}`,
    REVIEW_SM: (_id: string) =>
        `/document-management/${_id}/preview-shipping-mark`,
    REVIEW_DATA: (_id: string, documentId: string) =>
        `/document-management/${documentId}/preview-template-data/${_id}`,

    // PAYMENT
    PAYMENT_INFORMATION_LIST: '/payment-information',
    PAYMENT_INFORMATION_DETAIL: (_id: string) =>
        `/payment-information/${_id}/edit-payment`,
}

export const primaryColor = '#EB6800'

export const REGEX_EMAIL = new RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
)
export const REGEX_USERNAME = new RegExp(/^[a-zA-Z0-9\_.]*$/)
export const REGEX_USERNAME_LENGTH = new RegExp(/^[A-Za-z_][A-Za-z0-9_]{5,20}$/)
export const EMPTY_DATA_TABLE = 'No result found.'

export const NOTIFICATIONS = {
    UPLOAD_SUCCESSFULLY: 'Uploaded successfully.',
    CREATED_SUCCESSFULLY: 'Created successfully.',
    CONFIRM_SUCCESSFULLY: 'Confirmed successfully.',
    MODULE_CREATED_SUCCESSFULLY: (name: string) =>
        `${name || ''} created successfully.`,
    UPDATED_SUCCESSFULLY: 'Updated successfully.',
    DELETED_SUCCESSFULLY: 'Deleted successfully.',
    DELETED_SUCCESSFULLY_DESCRIPTION: (name: string) =>
        `${name || 'Record'} has been deleted.`,
    DELETED_WRONG: 'Deleted wrong!',
    DUPLICATED_SUCCESSFULLY: 'Duplicated successfully.',
    IMPORTED_SUCCESSFULLY: 'Imported successfully.',
    IMPORTED_UNSUCCESSFULLY: 'Imported unsuccessfully.',
    INCORRECT_FORMAT_FILE: 'Incorrect format file.',
    IMPORTED_WRONG: 'Cannot import the empty product code items.',
    IMPORTED_WRONG_CUSTOMER: 'Cannot import the empty data in Customer ID.',
    CANNOT_FIND_PREVIOUS_LC: 'Cannot find the Previous LC.',
    CANNOT_FIND_DOCUMENT: 'Cannot find the Document.',
    CHOOSE_WRONG: 'Choose wrong!',
    ERROR_WRONG: 'Error wrong!',
    UPDATE_ERROR: 'Update wrong.',
    POSTED_SUCCESSFULLY: 'Posted successfully.',
    PLEASE_CHOOSE_TEMPLATE: (template?: string) =>
        `Please choose${template ? ` ${template}` : ''} template`,
    PLEASE_UPLOAD_SM: `Please upload Shipping Mark file.`,
}

export const MESSAGES = {
    DELETE_CONFIRM_TITLE: (name: string = '') =>
        `Are you sure you want to delete ${name || ''}?`,
    DELETE_CONFIRM_DESCRIPTION: `This record will be deleted. You can't undo this action.`,
    FIELD_IS_NOT_EMPTY: (field: string = '') =>
        `${field || ''} field cannot be empty.`,
}
export const REGEX_PHONE = new RegExp(/^(\+){0,1}[0-9\s]+$/)
export const REGEX_NUMBER = new RegExp(/^\d*\.?\d*$/)
export const REGEX_NUMBER_NOT_EMPTY = new RegExp(/^(\d)+\.?(\d)*$/)

export const ES_ROLE = ['ADMIN', 'LOGISTIC', 'SALE']
export const TEMPLATE_TYPE = {
    INTERNAL: 'Internal Document',
    EXTERNAL: 'External Document',
}
export const COVER_NOTE_TYPE = 'COVER_NOTE'

export const ROLES = {
    ADMIN: 'ADMIN' as 'ADMIN',
    SALE: 'SALE' as 'SALE',
    SALE_VIEW: 'SALE_VIEW' as 'SALE_VIEW',
    PURCHASER: 'PURCHASER' as 'PURCHASER',
    LOGISTIC: 'LOGISTIC' as 'LOGISTIC',
    LOGISTIC_VIEW: 'LOGISTIC_VIEW' as 'LOGISTIC_VIEW',
    PERSON_IN_CHARGE: 'PERSON_IN_CHARGE' as 'PERSON_IN_CHARGE',
    ACCOUNT: 'ACCOUNT' as 'ACCOUNT',
    ACCOUNT_VIEW: 'ACCOUNT_VIEW' as 'ACCOUNT_VIEW',
    QC: 'QC' as 'QC',
}

export const PAYMENT_STATUS = {
    OVER_DUE: 'Over due',
    PENDING: 'Pending',
    PAID: 'Paid',
} as const

export const DOCUMENT_STATUS = {
    PENDING: 'Pending',
    SENT: 'Sent',
}

export const DOCUMENT_STATUS_COLOR: any = {
    [PAYMENT_STATUS.OVER_DUE]: 'red' as const,
    [PAYMENT_STATUS.PENDING]: 'orange' as const,
    [PAYMENT_STATUS.PAID]: 'green' as const,
    [DOCUMENT_STATUS.SENT]: 'green' as const,
}

export const DEBOUNCE_TIME = 500

export const REGEX_DECIMAL_REPLACE = new RegExp(/\B(?=(\d{3})+(?!\d))/)

export const DOCUMENT_TEMPLATE_TYPE = {
    PI: 'PI',
    PI_NP_LOGO: 'PI-NP-LOGO',
    PI_LOCAL: 'PI-LOCAL',
    PI_NP_LOGO_LOCAL: 'PI-NP-LOGO-LOCAL',
    PC: 'PC',
    SL: 'SL',
    SL_NP_LOGO: 'SL-NP-LOGO',
    SM: 'SM',
    CI: 'CI',
    CI_LOCAL: 'CI-LOCAL',
    BOL: 'BOL',
    PL: 'PL',
    IS: 'IS',
    PM: 'PM',
    FORMD: 'FORMD',
    FORME: 'FORME',
    FORMAI: 'FORM-AI',
    FORMCECA: 'FORM-CECA',
    KSFTA: 'KSFTA',
    COO: 'COO',
    SICCI: 'SICCI',
    BE: 'BE',
    ISCV: 'ISCV',
    SC: 'SC',
    BUBG: 'BUBG',
    SLC: 'SLC',
    HC: 'HC',
    RC: 'RC',
    DOO: 'DOO',
    MTEDP: 'MTEDP',
    POE: 'POE',
    QD: 'QD',
    SFSC: 'SFSC',
    SFEC: 'SFEC',
    COVER_NOTE: 'COVER_NOTE',
    ' 3-MC': '3-MC',
}

export const NOTIFICATION_TYPES = {
    DOCUMENT_STATUS_CHANGE:
        'DOCUMENT_STATUS_CHANGE' as 'DOCUMENT_STATUS_CHANGE',
    DOCUMENT_STATUS_CHANGE_TO_PAYMENT:
        'DOCUMENT_STATUS_CHANGE_TO_PAYMENT' as 'DOCUMENT_STATUS_CHANGE_TO_PAYMENT',
    PAYMENT_PAID: 'PAYMENT_PAID' as 'PAYMENT_PAID',
}

export const DOCUMENT_LC_TYPE: { [key: string]: string } = {
    GLOBAL_IME: 'GLOBAL IME',
    STANDARD_CHART_1: 'STANDARD CHART 1',
    STANDARD_CHART_2: 'STANDARD CHART 2',
    UOB_1: 'UOB 1',
    UOB_2: 'UOB 2',
    UOB_3: 'UOB 3',
}
