import { postCheckNotification } from '@api/notification'
import { getAvatar } from '@api/user'
import { FORMAT_DATE, NOTIFICATION_TYPES, PAGES } from '@constants/constants'
import { Avatar, Badge, Col, List, Row, Typography } from 'antd'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import styles from './styles.module.scss'

const NotificationItem = ({ item, onClose, onUpdateRow }: any) => {
    const navigate = useNavigate()

    const checkNotification = () => {
        postCheckNotification(item?._id).then((res) => {
            onUpdateRow &&
                onUpdateRow(item?._id, {
                    ...item,
                    unCheck: false,
                })
        })
    }

    const renderNavigate = () => {
        let to: any = null
        switch (item.type) {
            case NOTIFICATION_TYPES.DOCUMENT_STATUS_CHANGE:
                if (item?.metadata?.documentId)
                    to = PAGES.DOCUMENT_MANAGEMENT_VIEW_ORDER(
                        item?.metadata?.documentId,
                    )
                break
            case NOTIFICATION_TYPES.DOCUMENT_STATUS_CHANGE_TO_PAYMENT:
                if (item?.metadata?.documentId)
                    to = PAGES.PAYMENT_INFORMATION_DETAIL(
                        item?.metadata?.documentId,
                    )
                break

            case NOTIFICATION_TYPES.PAYMENT_PAID:
                if (item?.metadata?.documentId)
                    to = PAGES.PAYMENT_INFORMATION_DETAIL(
                        item?.metadata?.documentId,
                    )
                break

            default:
                break
        }
        return [
            to,
            () => {
                if (to) {
                    navigate(to)
                }
                checkNotification()
                onClose && onClose()
            },
        ]
    }

    const [isAction, navigateFunc] = renderNavigate()

    return (
        <List.Item
            className={`w-100 ${styles['container']} ${
                isAction ? styles['pointer'] : ''
            } ${item?.unCheck ? styles['un-checked'] : ''}`}
            onClick={() => navigateFunc && navigateFunc()}
        >
            <Row className="w-100">
                <Col span={4}>
                    <Row className="h-100" justify="center" align="middle">
                        <Badge dot={item?.unCheck}>
                            <Avatar
                                {...(item?.sender?.avatar
                                    ? {
                                          src: getAvatar(item?.sender?.avatar),
                                      }
                                    : {})}
                            >
                                {item?.sender?.avatar
                                    ? ''
                                    : item?.sender?.username}
                            </Avatar>
                        </Badge>
                    </Row>
                </Col>
                <Col span={20}>
                    <Row>
                        <Col span={24}>
                            <Typography.Text>{item?.content}</Typography.Text>
                        </Col>
                        <Col span={24}>
                            <Typography.Paragraph
                                className={`${styles['date']}`}
                                italic
                            >
                                {item?.sentAt
                                    ? moment(item?.sentAt).format(
                                          `HH:mm ${FORMAT_DATE}`,
                                      )
                                    : ''}
                            </Typography.Paragraph>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </List.Item>
    )
}

export default NotificationItem
