import { DEBOUNCE_TIME, ROLES } from '@constants/constants'
import { useAppContext } from '@context/index'
import { debounce } from 'lodash'
import { useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'

export const useQueryString = () => {
    const { search } = useLocation()

    return useMemo(() => new URLSearchParams(search), [search])
}

export const useResizeWindow = () => {
    const [size, setSize] = useState([0, 0])

    function updateSize() {
        setSize([window.innerWidth, window.innerHeight])
    }

    useLayoutEffect(() => {
        updateSize()
        window.addEventListener('resize', debounce(updateSize, DEBOUNCE_TIME))
        return () => window.removeEventListener('resize', updateSize)
    }, [])
    return size
}

export const useGetDisableAction = () => {
    const { state } = useAppContext()
    const disableAction = useMemo(
        () =>
            state?.user?.roles?.some((role: any) =>
                [
                    ROLES.LOGISTIC_VIEW,
                    ROLES.SALE_VIEW,
                    ROLES.ACCOUNT_VIEW,
                ].includes(role),
            ) && !state?.user?.isRootAdmin,
        [state?.user?.roles, state?.user?.isRootAdmin],
    )
    return disableAction
}

export default function ScrollToTop() {
    const { pathname } = useLocation();
  
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
  
    return null;
  }