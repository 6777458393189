import React, { lazy } from 'react'
import { Route, Routes, BrowserRouter, Navigate } from 'react-router-dom'
import {
    documentRoutes,
    paymentRoutes,
    routersAuth,
    routersNotAuth,
} from 'src/routers'
import PublicLayout from './components/Layout/PublicLayout'
import { useAppContext } from './context/index'
import NotFoundPage from './pages/404'
import AdminLayout from './components/Layout/AdminLayout'
import { FORMAT_DATE, PAGES } from './constants'
import { notification } from 'antd'
import moment from 'moment'
import ScrollToTop from './utils/hooks'

const DocumentContextProvider = lazy(() => import('@context/DocumentContext'))

notification.config({
    top: 50,
    duration: 3,
    rtl: false,
})

moment.updateLocale('en', {
    longDateFormat: {
        LT: 'h:mm A',
        LTS: 'h:mm:ss A',
        L: FORMAT_DATE,
        LL: 'MMMM Do YYYY',
        LLL: 'MMMM Do YYYY LT',
        LLLL: 'dddd, MMMM Do YYYY LT',
    },
})

const Components: any = {}

routersNotAuth
    .concat(routersAuth)
    .concat(documentRoutes)
    .concat(paymentRoutes)
    .forEach((route) => {
        Components[route.component] = lazy(
            () => import(`./pages/${route.component}`),
        )
    })

const App = () => {
    const { state } = useAppContext()
    const user = state?.user
    const roles = user?.roles

    const renderRoute = (router: any, index: number) => {
        const Component = Components[router.component]
        if (
            router.roles &&
            router.roles.length > 0 &&
            !router.roles.some((role: any) => (roles || []).includes(role)) &&
            !user?.isRootAdmin
        ) {
            return (
                <Route
                    key={index}
                    {...router}
                    element={<Navigate to={PAGES.VIEW_PROFILE} replace />}
                />
            )
        }
        return <Route key={index} {...router} element={<Component />} />
    }

    return (
        <BrowserRouter>
            <ScrollToTop />
            <Routes>
                {routersNotAuth.map((router, index) => {
                    const Component = Components[router.component]
                    return (
                        <Route
                            key={index}
                            {...router}
                            element={
                                <PublicLayout>
                                    <Component />
                                </PublicLayout>
                            }
                        />
                    )
                })}
                <Route
                    path="/*"
                    key="/index"
                    element={
                        <AdminLayout>
                            <Routes>
                                {routersAuth.map(renderRoute)}
                                <Route
                                    path="/document-management/:documentId/*"
                                    key="/document-management-context"
                                    element={
                                        <DocumentContextProvider>
                                            <Routes>
                                                {documentRoutes.map(
                                                    renderRoute,
                                                )}
                                            </Routes>
                                        </DocumentContextProvider>
                                    }
                                />
                                <Route
                                    path="/payment-information/:documentId/*"
                                    key="/payment-information-context"
                                    element={
                                        <DocumentContextProvider type='payment'>
                                            <Routes>
                                                {paymentRoutes.map(renderRoute)}
                                            </Routes>
                                        </DocumentContextProvider>
                                    }
                                />
                                <Route path="*" element={<NotFoundPage />} />
                            </Routes>
                        </AdminLayout>
                    }
                />
            </Routes>
        </BrowserRouter>
    )
}

export default App
