import { api } from '@configs/axios'

export const getUnreadNotifications = () => api.get('/notification/unread')
export const getNotifications = (page: number = 1) =>
    api.get('/notification/', {
        params: {
            current: page,
        },
    })
    export const postReadAllNotifications = () => api.post('/notification/read')
    export const postCheckNotification = (_id: string) => api.post(`/notification/check/${_id}`)
