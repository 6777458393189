import { getNotifications } from '@api/notification'
import { Divider, List, Skeleton, Typography } from 'antd'
import { isEmpty, uniqBy } from 'lodash'
import InfiniteScroll from 'react-infinite-scroll-component'
import { FunctionComponent, memo, useEffect, useState } from 'react'
import Header from './Header'
import NotificationItem from './NotificationItem'
import styles from './styles.module.scss'
import { useAppContext } from '@context/index'

interface NotificationListProps {
    visible: boolean
    onClose: () => void
}

let lastScrollTop = 0
let loadMore = false

const NotificationList: FunctionComponent<NotificationListProps> = ({
    visible,
    onClose,
}) => {
    const [data, setData] = useState<any[]>([])
    const [page, setPage] = useState<number>(1)
    const [loading, setLoading] = useState<boolean>(false)
    const { state } = useAppContext()

    useEffect(() => {
        if (page < 0) return
        const bodyList = document.querySelector(
            '#notification-list .ant-spin-container',
        )
        bodyList?.setAttribute('id', 'scrollableDiv')
        bodyList?.addEventListener('scroll', scrollInfinity)
        return () => bodyList?.removeEventListener('scroll', scrollInfinity)
    }, [page, loading])

    useEffect(() => {
        if (
            visible &&
            (isEmpty(data) ||
                (state?.notificationCount && state?.notificationCount > 0))
        ) {
            loadNotification()
        }
    }, [visible])

    const scrollInfinity = (e: any) => {
        const element = e?.target
        const nodes = element.querySelectorAll('.ant-list-item')
        const last = nodes[nodes.length - 1]
        const st = element.scrollTop
        if (st < lastScrollTop) {
            lastScrollTop = st
            return
        }
        lastScrollTop = st
        if (element.scrollTop + 660 > last.offsetTop && !loadMore) {
            loadMoreData()
        }
    }

    const loadNotification = (page = 1) => {
        if (loading || loadMore) {
            return
        }
        loadMore = true
        setLoading(true)
        getNotifications(page)
            .then((res) => {
                if (isEmpty(res?.data?.data)) {
                    setPage(-1)
                    return
                }
                if (page === 1) {
                    setData(res?.data?.data || [])
                    const bodyList = document.querySelector(
                        '#notification-list .ant-spin-container',
                    )
                    bodyList?.scrollTo({
                        behavior: 'smooth',
                        top: 0,
                    })
                } else {
                    setData((oldData) => {
                        return uniqBy(
                            oldData.concat(res?.data?.data || []),
                            '_id',
                        )
                    })
                }
                setPage(res?.data?.current + 1)
            })
            .finally(() => {
                setLoading(false)
                loadMore = false
            })
    }

    const loadMoreData = () => {
        loadNotification(page)
    }

    const updateRow = (_id: string, item: any) => {
        setData((oldData) => {
            return oldData.map((d) => {
                if (d._id === _id)
                    return item
                return d
            })
        })
    }

    const dataNotification = uniqBy(data, '_id')
    return (
        <List
            header={<Header onReload={() => loadNotification()} />}
            bordered
            dataSource={dataNotification || []}
            renderItem={(item) => (
                <NotificationItem
                    item={item}
                    onClose={onClose}
                    onUpdateRow={updateRow}
                />
            )}
            locale={{
                emptyText: 'Empty notification',
            }}
            rowKey="_id"
            id="notification-list"
            className={styles['container']}
        />
    )
}

export default memo(NotificationList)
