import React, { Suspense, useEffect } from 'react';
import { Layout } from 'antd';
import { useAppContext } from 'src/context';
import { useNavigate } from 'react-router-dom';
import styles from "./style.module.scss";
import { LoadingLazyComponent } from 'src/components/loading-page';

const LayoutAdmin = ({
    children
}: any) => {

    const { state } = useAppContext()
    const navigate = useNavigate()

    useEffect(() => {
        if (!state?.isLoadingInit && state?.user) navigate('/', { replace: true })
    }, [state, navigate])

    return (
        <Layout className={styles.container}>
            <Suspense fallback={<LoadingLazyComponent />}>
                {children}
            </Suspense>
        </Layout>
    )
};

export default LayoutAdmin;
